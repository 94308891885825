const mainTheme = {
  color: {
    primary: '#EE7202',
    secondary: '#EE7202',
    alternate: '#44A12B',
    grey: '#D1D4DD',
    dark: '#5E5E5E',
    light: '#FFFFFF',
  },

  font: {
    family: {
      primary: 'raleway, sans-serif',
      secondary: 'futura-pt, sans-serif',
    },

    size: {
      tiny: '10px',
      small: '14px',
      medium: '16px',
      big: '18px',
      large: '24px',
      huge: '30px',
      mega: '40px',
      giga: '60px',
    },

    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 600,
      extraBold: 800,
    },
  },
}

export default mainTheme
